a:visited {
  color: #0000EE;
}

.connection-status {
  background: rgb(106, 238, 106);
  font-size: 10px;  
  margin-top: 6px;
  margin-right: 120px;
  float: right;
}

.spinner {
  margin-top: 90px;
}

@media only screen and (max-width: 600px) {
  .tr-address {
    font-size: 13px;
  }

  .connection-status {
    margin-right: 6px;
  }

  .spinner {
    margin-top: 80px;
  }
}

/*@media only screen and (max-width: 600px) and (orientation:portrait) {
  .connection-status {
    //margin-right: 5px;
  }

  .p-connection-status {
    padding-right: 10px;
    //margin-right: 5px;
  }
}*/

/*@media only screen and (max-width: 600px) and (orientation:portrait) {
  .connection-status {
    margin-right: 46px;
  }
}*/

@media only screen 
and (max-device-height : 768px) 
and (max-device-width : 1024px)
and (orientation:portrait)
{
  .connection-status {
    margin-right: 25px;
  }
}

@media only screen 
and (max-device-height : 768px) 
and (max-device-width : 1024px)
and (orientation:landscape)
{
  .connection-status {
    margin-right: 15px;
  }
}

@media (pointer:none), (pointer:coarse) and (orientation:portrait) {
  .connection-status {
    margin-right: 5px;
  }
}

@media (pointer:none), (pointer:coarse) and (orientation:landscape) {
  .connection-status {
    margin-right: 116px;
  }
}

.connection-status-tablet {  
  margin-right: 170px;  
}

@media only screen 
and (orientation:landscape)
{
  .connection-status-tablet {  
    margin-right: 175px;
  }  
}
