$background-grey-number: 246;

body {
  width: 35em;
  margin: 0 auto;
  /*font-family: Tahoma, Verdana, Arial, sans-serif;*/
  font-family: 'Raleway', sans-serif;
  background-color: rgb($background-grey-number, $background-grey-number, $background-grey-number);
  max-width: 100%;
}

@media only screen and (max-width: 600px) {
  body {
    max-width: 23em;
    margin-left: 10px;
  }
}

@media (pointer:none), (pointer:coarse) {

}

.brand-name {
  font-family: Tahoma, Verdana, Arial, sans-serif;
}